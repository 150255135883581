import React, { useState } from "react";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import {
  Grid,
  Dialog,
  Slide,
  makeStyles,
  Typography,
  useMediaQuery,
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  Tooltip,
  Snackbar,
} from "@material-ui/core";
import MobileNumber from "../utils/MobileNumber";
import { string, object } from "yup";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    width: "450px",
    paddinng: theme.spacing(2),
    minHeight: "220px",
    marginTop: "-100px",
    [theme.breakpoints.down("sm")]: {
      width: "80vw",
      margin: "4px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "98vw",
      margin: "4px",
    },
  },
  closeIcon: {
    "&:hover": {
      color: theme.palette.primary.dark,
      cursor: "pointer",
    },
  },
  divContainer: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  textAlignCenter: {
    textAlign: "center",
  },
  button: {
    color: theme.palette.white.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  icon: {
    position: "relative",
    top: "5px",
    color: theme.palette.primary.main,
  },
  phoneNumber: {
    textDecoration: "none",
    cursor: "pointer",
    color: "black",
  },
  SnackbarStyle: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.primary.main,
  },
  errorSnakbar: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.error.main,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const CallUsDialog = (props) => {
  const { setShowCallUsDialog, showCallusDialog } = props;
  const location = useLocation();

  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState({});

  const formItems = [
    "last_name", //0
    "mobile", //1
    "user_type", //2
  ];

  const phoneRegExp = /^(\+91)[6-9]{1}\d{9}$/;

  const validationSchema = object().shape({
    [formItems[0]]: string().required("Required *"),

    [formItems[1]]: string()
      .matches(phoneRegExp, "Please enter a valid Mobile number")
      .required("Required *")
      .nullable(),
    [formItems[2]]: string().required("Required *"),
  });

  const handleChange = async (event) => {
    const { name, value } = event.target;
    if (validationSchema.fields[name]) {
      let isValid = await validationSchema.fields[name].isValid(value, {
        strict: true,
      });
      if (isValid) {
        // on entering a valid data remove error entry if any
        const { [name]: errorEntry, ...rest } = formError;
        setFormError(rest);
      }
    }
    setFormData((prevVal) => ({
      ...prevVal,
      [name]: value,
    }));
  };

  // on blur check if the entered value is valid
  const handleBlur = (event) => {
    const { name, value } = event.target;
    if (validationSchema.fields[name]) {
      validationSchema.fields[name]
        .validate(value, { strict: true })
        .then((value) => {
          // ideal place to show a progress or tick.
        })
        .catch((err) => {
          setFormError((prevVal) => ({
            ...prevVal,
            [name]: err.errors.join(),
          }));
        });
    }
  };

  // do final check on submit.
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let val = await validationSchema.validate(formData, {
        strict: true,
        abortEarly: false,
      });
      setDisabledBtn(true);

      // console.log("valll", val);
      postLeadToSF(val);
      if (process.env.NODE_ENV === "production") {
        gtag_report_conversion(location.pathname);
        window.dataLayer.push({'event':'submit_request'});

      }
    } catch (err) {
      err.inner.forEach((item) => {
        setFormError((prevVal) => ({
          ...prevVal,
          [item.path]: item.errors.join(),
        }));
      });
      setDisabledBtn(false);
    }
  };

  const handleClose = () => {
    setShowCallUsDialog(false);
    setFormError({});
  };

  const gtag_report_conversion = (url) => {
    var callback = function () {
      if (typeof url != "undefined") {
        window.location = url;
        // console.log("here at Gtag");
      }
    };
    window.gtag("event", "conversion", {
      send_to: "AW-932511214/U_FlCK7NvawDEO7707wD",
      event_callback: callback,
    });
    return false;
  };

  // console.log("hell env", process.env.NODE_ENV);

  const postLeadToSF = (val) => {
    const requestOptions = {
      method: "POST",
      mode: "no-cors",
    };

    const oid = "00D0p0000008ipO";
    const retUrl = "http://webtruck/create/lead";
    const lastName = val.last_name;
    const mobile = val.mobile;
    const userType = val.user_type;

    fetch(
      `https://test.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&oid=${oid}&retURL=${retUrl}&last_name=${lastName}&phone=${mobile}&{00N0p0000083S5V}=${userType}
    `,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        setShowSuccess(true);
        setTimeout(() => {
          setShowCallUsDialog(false);
          setShowSuccess(false);
          setDisabledBtn(false);
          setFormData({});
        }, 4000);
      })
      .catch((error) => {
        setShowError(true);
        setTimeout(() => {
          setShowCallUsDialog(false);
          setShowError(false);
          setDisabledBtn(false);
          setFormData({});
        }, 3000);
      });
  };

  return (
    <>
      {showSuccess && (
        <Snackbar
          ContentProps={{
            className: classes.SnackbarStyle,
          }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={showSuccess}
          autoHideDuration={5000}
          message="Successfully submitted your details, you will get a call shortly."
          key={"success"}
        />
      )}
      {showError && (
        <Snackbar
          ContentProps={{
            className: classes.errorSnakbar,
          }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={showError}
          autoHideDuration={5000}
          message="Something went wrong, please try again."
          key={"error"}
        />
      )}
      {showCallusDialog && (
        <Dialog
          open={showCallusDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          classes={{ paper: classes.dialogPaper }}
        >
          <div className={classes.divContainer}>
            <Grid container justify="flex-end">
              <Grid item>
                <CancelOutlinedIcon
                  color="primary"
                  className={classes.closeIcon}
                  //   fontSize="large"
                  onClick={() => handleClose()}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.textAlignCenter}>
                <Typography variant="h6" gutterBottom>
                  {props.contactNumber ? (
                    <strong>Connect with us</strong>
                  ) : (
                    <strong>Call Back Request</strong>
                  )}
                </Typography>
                <Typography variant="body2">
                  Get access to a wide network of truckers and customers in
                  India.&nbsp;{" "}
                  {props.contactNumber
                    ? `Connect with us to get more information.`
                    : `Fill in your details and we will call you back.`}
                </Typography>
              </Grid>
              {props.contactNumber ? (
                <Grid item xs={12}>
                  <Grid container spacing={2} style={{ textAlign: "center" }}>
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        <PhoneInTalkIcon className={classes.icon} />
                        {isSmallScreen ? (
                          <a
                            href="tel:+919513167456"
                            className={classes.phoneNumber}
                          >
                            +91 95131 67456{" "}
                          </a>
                        ) : (
                          "  +91 95131 67456"
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        <MailOutlineIcon className={classes.icon} />{" "}
                        info@shipnext.in
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <form
                    // action="https://test.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"

                    onSubmit={(e) => handleSubmit()}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          label="Name "
                          id="last_name"
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name={formItems[0]}
                          fullWidth
                          error={!!formError[formItems[0]]}
                          helperText={formError[formItems[0]]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Mobile Number"
                          variant="outlined"
                          id="mobile"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name={formItems[1]}
                          InputProps={{
                            inputComponent: MobileNumber,
                          }}
                          fullWidth
                          error={!!formError[formItems[1]]}
                          helperText={formError[formItems[1]]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <RadioGroup
                          id="00N0p0000083S5V"
                          // name="00N0p0000083S5V"
                          name={formItems[2]}
                          value={formData[formItems[2]]}
                          //   onChange={(e) => setBodyType(e.target.value)}
                          onChange={handleChange}
                        >
                          <Grid
                            container
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            className={classes.textAlignCenter}
                          >
                            <Grid item xs={6}>
                              <Tooltip title="I am a trucker" placement="top">
                                <FormControlLabel
                                  value="FLEET_OWNER"
                                  control={<Radio color="primary" />}
                                  label="Fleet Owner"
                                  display="inline"
                                />
                              </Tooltip>
                              {/* <br />
                      <Typography variant="caption" color="textSecondary">
                        I have truck
                      </Typography> */}
                            </Grid>
                            <Grid item xs={6}>
                              <Tooltip title="I need a truck" placement="top">
                                <FormControlLabel
                                  value="CUSTOMER"
                                  control={<Radio color="primary" />}
                                  label="Customer"
                                  display="inline"
                                />
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </RadioGroup>
                        {formError && formError[formItems[2]] && (
                          <FormHelperText className={classes.error}>
                            {formError[formItems[2]]}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                          disabled={disabledBtn}
                          onClick={(e) => handleSubmit(e)}
                          className={classes.button}
                        >
                          Submit Request
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              )}
            </Grid>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default CallUsDialog;
