import React, { useEffect } from "react";
// import { Routes, Route } from "react-router-dom";

// import Footer from "./components/Footer";
// import Home from "./components/home/home";
import HomeSection from "./components/weTruckHome/HomeSection";
import { MuiThemeProvider } from "@material-ui/core/styles";
import theme from "./components/theme/theme.js";
import CssBaseline from "@material-ui/core/CssBaseline";
// import HowItWorksSection from "./components/weTruckHome/HowItWorksSection";
// import NotFoundPage from "./components/utils/NotFoundPage";

const App = (props) => {
  useEffect(() => props.hideLoader(), [props]);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <HomeSection />
      {/* <Routes>
        <Route path="/" element={} />
       <Route path="/about" element={<HowItWorksSection />} /> 
        <Route path="*" element={<NotFoundPage />} />
      </Routes> */}
    </MuiThemeProvider>
  );
};

export default App;
