import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  makeStyles,
  Paper,
  Avatar,
  useMediaQuery,
} from "@material-ui/core";
// import MobileImage from "../../images/mobile_dummy.png";
// import MobileImage from "../../images/whitePhone.png";
import MobileImage from "../../images/mobilePhone.png";

// import MobileImageForSmallDevice from "../../images/imageForMobileView.png";
// import MobileImageForSmallDevice from "../../images/banner1.png";
import TruckImg from "../../images/truck.png";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
// import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import PaymentOutlinedIcon from "@material-ui/icons/PaymentOutlined";
// import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
// import BlackButton from "./BlackButton";
import CallUsDialog from "./CallUsDialog";
// import PhoneCallbackOutlinedIcon from "@material-ui/icons/PhoneCallbackOutlined";
import CallOutlinedIcon from "@material-ui/icons/CallOutlined";

// import TestImage from "../../images/test.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    // backgroundColor: theme.palette.grey.bg,
    // padding: theme.spacing(2),
    paddingTop: "100px",
    color: "black",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(4),
    },
  },
  greenText: {
    color: theme.palette.primary.main,
  },
  bannertext: {
    order: 1,
    animation: "fromLeft 0.75s ease-in-out ",
    padding: theme.spacing(6),
    paddingTop: theme.spacing(0.5),

    [theme.breakpoints.down("md")]: {
      order: 2,
      textAlign: "center",
      padding: theme.spacing(2),
      paddingTop: theme.spacing(0.5),
    },
  },
  imgHoldingDiv: {
    textAlign: "center",
    // border: "1px solid green",
    animation: "fromRight 0.75s ease-in-out ",

    position: "relative",
    order: 2,
    [theme.breakpoints.down("md")]: {
      order: 1,
    },
  },
  middleDiv: {
    width: "300px",
    textAlign: "left",
    position: "absolute",
    top: "50%",
    boxShadow: "2px 2px 10px 1px lightgrey",
    left: "-142%",
    overflow: "hidden",
    paddingTop: theme.spacing(2),
    padding: theme.spacing(1.5),
    transform: `translate(0px,0px)`,
    transition: `all 0.3s ease-in-out`,
    "@media (min-width:1280px) and (max-width:1535px)  ": {
      width: "240px",
      left: "-132%",
      top: "33%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "190px",
      left: "-110%",
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0.5),
      width: "120px",
      fontSize: "12px",
      position: "absolute",
      left: "-80%",
      top: "50%",
      textAlign: "center",
    },
  },
  topSection: {
    width: "250px",
    textAlign: "left",
    boxShadow: "2px 2px 10px 1px lightgrey",
    overflow: "hidden",
    position: "absolute",
    top: "-23%",
    right: "-100%",
    borderRadius: "5px",
    // paddingTop: theme.spacing(2),
    padding: theme.spacing(1),
    transform: `translate(0px,0px)`,
    transition: `all 0.3s ease-in-out`,
    "@media (min-width:1280px) and (max-width:1535px) ": {
      position: "absolute",
      width: "220px",
      right: "-115%",
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0.5),
      // textAlign: "center",
      width: "180px",
      fontSize: "12px",
      position: "absolute",
      top: "-33%",
      right: "-72%",
    },
  },
  bottomDiv: {
    // width: "130px",
    width: "250px",
    overflow: "hidden",
    // textAlign: "left",
    position: "absolute",
    boxShadow: "2px 2px 10px 1px lightgrey",
    // bottom: "-80%",
    bottom: "-60%",
    // right: "-50%",
    right: "-70%",
    textAlign: "center",
    // paddingTop: theme.spacing(2),
    padding: theme.spacing(1),
    transform: `translate(0px,0px)`,
    transition: `all 0.3s ease-in-out`,
    "@media (min-width:1280px) and (max-width:1535px) ": {
      position: "absolute",
      right: "-62%",
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0.5),
      width: "180px",
      fontSize: "12px",
      position: "absolute",
      right: "-72%",
      bottom: "-50%",
    },
  },
  truckImg: {
    width: "220px",
    "@media (min-width:1280px) and (max-width:1535px)  ": {
      width: "180px",
    },
    [theme.breakpoints.down("md")]: {
      width: "200px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "180px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "140px",
    },
  },

  hoverDiv: {
    position: "absolute",
    textAlign: "center",
    top: "50%",
    left: "50%",
    transform: `translate(-50%,-50%)`,
  },
  relativeHoverDiv: {
    cursor: "pointer",
    position: "relative",
    // border: "1px solid blue",

    "&:hover .middleDiv": {
      transform: `translate(-20px,20px)`,
      transition: `all 0.3s ease-in-out`,
    },
    "&:hover .topSection": {
      transform: `translate(0px,-20px)`,
      transition: `all 0.3s ease-in-out`,
    },
    "&:hover .bottomDiv": {
      transform: `translate(0px,20px)`,
      transition: `all 0.3s ease-in-out`,
    },
  },

  mobileImage: {
    // width: "40vw",
    width: "44%",
    "@media (min-width:900px) and (max-width:1280px)  ": {
      width: "30%",
    },
    "@media (min-width:1280px) and (max-width:1535px)  ": {
      width: "42%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "54vw",
    },
  },
  textInsideBanner: {
    fontSize: "14px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },
  arrowAvtar: {
    position: "absolute",
    left: "50%",
    transform: `translateX(-50%)`,
  },
  textInsidemobile: {
    position: "absolute",
    textAlign: "center",
    top: "100px",
    left: "50%",
    overflow: "hidden",
    transform: `translateX(-50%)`,
    [theme.breakpoints.down("xs")]: {
      top: "14%",
    },
  },
  headingInMobileText: {
    fontSize: "16px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "11px",
    },
  },

  iconInsideAvtar: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  },
  iconsSize: {
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  dots: {
    position: "absolute",
    bottom: "38px",
    left: "50%",
    transform: `translateX(-50%)`,
  },
  translate50: {
    transform: "translateX(-50%)",
    transition: `transform 0.3s ease-in-out`,
  },
  translate0: {
    transform: "translateX(0%)",
    transition: `transform 0.3s ease-in-out`,
  },
  fixedBtn: {
    position: "fixed",
    bottom: "0px",
    left: "0px",
    width: "100%",
    zIndex: 20,
    backgroundColor: "black",
    color: theme.palette.white.main,
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  borderRight: {
    borderRight: `1px solid ${theme.palette.grey.blackDashed}`,
    textAlign: "center",
  },
  phoneicon: {
    position: "relative",
    top: "6px",
    fontSize: "20px",
    color: theme.palette.primary.main,
  },
  heading: {
    fontSize: "4em",
    margin: `0.67em 0`,
    lineHeight: "65px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2em",
      lineHeight: "32px",
    },
  },
}));

const BannerSection = (props) => {
  const classes = useStyles();
  // const { setUserOverlay, setRegnClicked } = props;
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  // const breakingPoints = useMediaQuery("(min-width:900px) and (max-width:1535px)");

  const [active, setActive] = useState("customer");

  useEffect(() => {
    let timer1 = setTimeout(() => {
      setActive(active === "customer" ? "fleetOwner" : "customer");
    }, 5000);

    return () => {
      clearTimeout(timer1);
    };
  }, [active]);

  const [showCallusDialog, setShowCallUsDialog] = useState(false);
  return (
    <Grid
      container
      // spacing={3}
      alignItems="center"
      alignContent="center"
      className={classes.root}
      id="home"
    >
      <Grid item xs={12} lg={6} className={classes.bannertext}>
        <h1 className={classes.heading}>
          Quick, affordable &#38; safe&nbsp;
          <span className={classes.greenText}>Trucking&nbsp;</span> for all.
        </h1>

        {/* <Typography variant="h1" display="inline">
          Quick, affordable, safe&nbsp;
        </Typography>
        <Typography variant="h1" display="inline" color="primary">
          Trucking&nbsp;
        </Typography>
        <Typography variant="h1" display="inline">
          for all.
        </Typography> */}

        {/* <Button
          className={classes.tryBtn}
          onClick={() => {
            setUserOverlay(true);
            setRegnClicked(false);
          }}
        >
          <Typography variant="body1">Try for free </Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Avatar className={`${classes.iconInsideAvtar} ${classes.small}`}>
            <ArrowForwardIcon />{" "}
          </Avatar>
        </Button> */}
        {isSmallScreen ? (
          <Grid item xs={12} className={classes.fixedBtn}>
            <Grid container>
              <Grid item xs={12}>
                <a
                  href="tel:+919513167456"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <div className={classes.borderRight}>
                    <Typography variant="body1">
                      {" "}
                      <CallOutlinedIcon className={classes.phoneicon} />{" "}
                      &nbsp;Call us
                    </Typography>
                  </div>
                  {/* <BlackButton
                    onClick={() => {
                      // setUserOverlay(true);
                      // setRegnClicked(false);
                      // setShowCallUsDialog(true);
                    }}
                    btnText="Call Us"
                    size="large"
                    fullWidth={true} 
                  />*/}
                </a>
              </Grid>
              {/* <Grid
                item
                xs={6}
                onClick={() => {
                  setShowCallUsDialog(true);
                }}
              >
                <Typography variant="body1">
                  <PhoneCallbackOutlinedIcon className={classes.phoneicon} />{" "}
                  &nbsp;Get a CallBack
                </Typography>
              </Grid> */}
            </Grid>
          </Grid>
        ) : (
          ""
          // <BlackButton
          //   onClick={() => {
          //     // setUserOverlay(true);
          //     // setRegnClicked(false);

          //     setShowCallUsDialog(true);
          //   }}
          //   btnText="GET A CALL BACK"
          //   size="large"
          // />
        )}

        <CallUsDialog
          showCallusDialog={showCallusDialog}
          setShowCallUsDialog={setShowCallUsDialog}
        />
      </Grid>
      <Grid item xs={12} lg={6} className={classes.imgHoldingDiv}>
        <img
          alt="mobile"
          // src={isSmallScreen ? MobileImageForSmallDevice : MobileImage}
          src={MobileImage}
          className={classes.mobileImage}
        />

        {/* {!isSmallScreen ? (
          <> */}
        <div className={classes.textInsidemobile}>
          <div
            style={{
              width: "200%",
              overflow: "hidden",
            }}
            className={
              active === "fleetOwner" ? classes.translate0 : classes.translate50
            }
          >
            <div style={{ display: "inline-block", width: "52%" }}>
              <Typography className={classes.headingInMobileText}>
                <strong>
                  WeTruck is your answer to <br />
                  finding quick loads.
                  {/* If you want to find loads quick,
                  <br /> WeTruck is your answer. */}
                </strong>
              </Typography>
            </div>

            <div style={{ display: "inline-block", width: "48%" }}>
              <Typography className={classes.headingInMobileText}>
                <strong>
                  Access any truck <br />
                  anywhere in the country.
                </strong>
              </Typography>
            </div>
          </div>
        </div>

        <div className={classes.hoverDiv}>
          <div className={classes.relativeHoverDiv}>
            <img src={TruckImg} alt="truck" className={classes.truckImg} />
            <Paper className={`${classes.middleDiv} middleDiv`} elevation={0}>
              {/* <Grid container spacing={2} alignItems="flex-start">
                    <Grid item xs={12} md={2}>
                      <Avatar className={classes.iconInsideAvtar}>
                        &#x20B9;{" "}
                      </Avatar>
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <Typography variant="body2">
                        <strong>Get instant pricing</strong>
                      </Typography>
                      <Typography variant="caption">
                        Submit the details of your pick-up , drop &#38;
                        dimensions of the required cargo . Get the estimated
                        fare right there and then.
                      </Typography>
                    </Grid>


                  </Grid> */}
              <GetTransition
                active={active}
                transporterIcon={
                  <LocalShippingIcon className={classes.iconsSize} />
                }
                transporterText={"Assign the truck and driver"}
                customerIcon={<>&#x20B9;</>}
                customerText={"Get quotes from transporters"}
                justifyCenter={true}
              />
            </Paper>
            <Paper className={`${classes.topSection} topSection`} elevation={0}>
              <GetTransition
                active={active}
                transporterIcon={<>&#x20B9;</>}
                transporterText={"Quote for the trip you like"}
                customerIcon={
                  <AssignmentOutlinedIcon className={classes.iconsSize} />
                }
                customerText={"Post your requirement "}
              />
            </Paper>
            <Paper className={`${classes.bottomDiv} bottomDiv`} elevation={0}>
              <GetTransition
                active={active}
                transporterIcon={
                  <PaymentOutlinedIcon className={classes.iconsSize} />
                }
                transporterText={"Get Instant payment"}
                customerIcon={<>&#x20B9;</>}
                customerText={"Make payment online "}
              />

              {/* <Grid container spacing={2} alignItems="center">
                    <Grid
                      item
                      xs={12}
                      style={{
                        padding: "20px 8px 25px 8px",
                        position: "relative",
                      }}
                    >
                      <Typography variant="body2">
                        Transfer was Successfull
                      </Typography>
                      <Avatar
                        className={`${classes.iconInsideAvtar} ${classes.arrowAvtar}`}
                      >
                        <ArrowForwardIcon />{" "}
                      </Avatar>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        padding: "25px 8px",
                      }}
                    >
                      <Typography variant="body1">&#x20B9; 8500</Typography>
                    </Grid>
                  </Grid> */}
            </Paper>
          </div>
        </div>
        <div className={classes.dots}>
          <MoreHorizIcon fontSize="large" />
        </div>
        {/* </>
        ) : (
          ""
        )} */}
      </Grid>
    </Grid>
  );
};

const GetTransition = (props) => {
  const {
    transporterIcon,
    transporterText,
    customerText,
    customerIcon,
    active,
    justifyCenter,
  } = props;
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <div
      style={{
        width: "200%",
        overflow: "hidden",
      }}
      className={
        active === "fleetOwner" ? classes.translate0 : classes.translate50
      }
    >
      <div style={{ display: "inline-block", width: "52%" }}>
        <Grid
          container
          spacing={1}
          alignItems="center"
          justify={isSmallScreen && justifyCenter ? "center" : "flex-start"}
        >
          <Grid item>
            <Avatar className={classes.iconInsideAvtar}>
              {transporterIcon}
            </Avatar>
          </Grid>
          <Grid item>
            <Typography className={classes.textInsideBanner}>
              <strong>{transporterText} </strong>
            </Typography>
          </Grid>
        </Grid>
      </div>

      <div style={{ display: "inline-block", width: "48%" }}>
        <Grid
          container
          spacing={1}
          alignItems="center"
          justify={isSmallScreen && justifyCenter ? "center" : "flex-start"}
        >
          <Grid item>
            <Avatar className={classes.iconInsideAvtar}>{customerIcon}</Avatar>
          </Grid>
          <Grid item>
            <Typography className={classes.textInsideBanner}>
              <strong>{customerText} </strong>
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default BannerSection;
