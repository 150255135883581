import React from "react";
import { Link, useNavigate } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import { makeStyles, Typography, useMediaQuery } from "@material-ui/core";
// import GooglePlay from "../images/GooglePlay.png";
// import AppStore from "../images/AppStore.png";
// import ViewListIcon from "@material-ui/icons/ViewList";
import Meeran from "../images/meeran.png";
// import Apple from "../images/apple.png";
// import GPlay from "../images/gplay.png";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import AppLinks from "./weTruckHome/AppLinks";

const useStyles = makeStyles((theme) => ({
  footerStyles: {
    // padding: "3rem",
    padding: `${theme.spacing(8)}px 10px ${theme.spacing(1)}px 10px`,
    background: "#000",
    // color: "#a7a7a7",
    color: theme.palette.white.main,

    [theme.breakpoints.down("sm")]: {
      paddingBottom: "65px",
    },
  },
  icon: {
    position: "relative",
    top: "6px",
    marginRight: "3px",
  },
  phoneNumber: {
    textDecoration: "none",
    cursor: "pointer",
    color: theme.palette.white.main,
  },

  textLink: {
    color: theme.palette.white.main,

    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
  },

  paraMargin: {
    margin: "2rem 0",
  },
  leftBorder: {
    padding: theme.spacing(1),

    borderLeft: `1px dotted ${theme.palette.grey.blackDashed}`,
    [theme.breakpoints.down("sm")]: {
      borderLeft: "none",
    },
  },
  bottomBorder: {
    borderBottom: `1px dotted ${theme.palette.grey.blackDashed}`,
  },
  borderRight: {
    borderRight: `1px dotted ${theme.palette.grey.blackDashed}`,
  },
  padding1: {
    padding: theme.spacing(1),
  },
  socialIcon: {
    position: "relative",
    top: "2px",
    textDecoration: "none",
    color: theme.palette.white.main,
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
  },
}));

const Footer = ({
  scrollToRef,
  uspRef,
  homeRef,
  benefitsRef,
  downloadAppsRef,
  howItWorksRef,
  customerSayRef,
  ourClientsRef,
}) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  let navigate = useNavigate();

  const useFullLinks = [
    {
      title: "Home",
      ref: homeRef,
    },
    {
      title: "Our USP",
      ref: uspRef,
    },
    {
      title: "Benefits",
      ref: benefitsRef,
    },
    {
      title: "Download our App",
      ref: downloadAppsRef,
    },
    {
      title: "How our app works",
      ref: howItWorksRef,

      id: "howItWorks",
    },
    {
      title: "What our Customer's Say",
      ref: customerSayRef,
    },
    {
      title: "Trusted Clients",
      ref: ourClientsRef,
    },
  ];

  const socialMedia = [
    {
      icon: <FacebookIcon />,
      name: "face-book",
      link: "https://www.facebook.com/wetruckindia/",
    },
    {
      icon: <InstagramIcon />,
      name: "instagram",
      link: "https://www.instagram.com/wetruckindia/?hl=en",
    },
    {
      icon: <LinkedInIcon />,
      name: "linked-in",
      link: "https://www.linkedin.com/company/wetruckindia/",
    },
  ];

  return (
    <footer className={classes.footerStyles}>
      <div>
        <Grid container justify="center">
          <Grid item xs={12} lg={11}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} className={classes.bottomBorder}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Typography
                      variant="h6"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                        navigate("/", { replace: true });
                      }}
                    >
                      WeTruck
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" className={classes.paraMargin}>
                      {/* We at shipnext, has continuously solved many hurdles that
                      haunts the current trucking industry. Evolving with time,
                      has always been our mantra. We are determined to provide a
                      system that is easy to use and a one stop destination for
                      all your shipment needs. */}
                      We at WeTruck, has continuously solved many hurdles that
                      haunt the current trucking industry. Evolving with time
                      has always been our mantra. We are determined to provide a
                      system that is easy to use and a one-stop destination for
                      all your trucking needs.
                    </Typography>
                  </Grid>
                  <Grid item>
                    <a
                      href="https://groupmeeran.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.socialIcon}
                    >
                      <img src={Meeran} alt="Meeran " />
                    </a>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                className={`${classes.bottomBorder} ${classes.leftBorder}`}
              >
                <Grid container spacing={2} className={classes.padding1}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Useful links</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container direction="column" spacing={1}>
                      {useFullLinks.map((link, index) => (
                        <Grid item xs={12} key={index}>
                          <Typography
                            variant="body2"
                            className={classes.textLink}
                            onClick={() => {
                              navigate("/", { replace: true });
                              if (link.ref && link.ref.current) {
                                scrollToRef(link.ref, link.id);
                              }
                            }}
                          >
                            {link.title}
                          </Typography>
                        </Grid>
                      ))}

                      {/* <Grid item>
                        <Typography
                          variant="body2"
                          className={classes.textLink}
                          onClick={() => scrollToRef(homeRef)}
                        >
                          Home
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="body2"
                          className={classes.textLink}
                          onClick={() => scrollToRef(uspRef)}
                        >
                          our USPs
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2">Why To Choose</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2">Blog</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2">Career</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2">Policies</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2">Awards</Typography>
                      </Grid> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                className={`${classes.bottomBorder} ${classes.leftBorder}`}
              >
                <Grid
                  container
                  direction="column"
                  spacing={2}
                  className={classes.padding1}
                >
                  <Grid item>
                    <Typography variant="h6">Download our apps</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container direction="row">
                      <AppLinks />
                      {/* <img className={classes.imageWidth} src={GPlay} alt="g-play"/>
                      &nbsp; &nbsp;
                      <img className={classes.imageWidth} src={Apple} alt="apple"/>*/}
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6">Contacts</Typography>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={2}>
                      <Grid item>
                        <Typography variant="body1">
                          <PhoneInTalkIcon className={classes.icon} />
                          {isSmallScreen ? (
                            <a
                              href="tel:+919513167456"
                              className={classes.phoneNumber}
                            >
                              +91 95131 67456{" "}
                            </a>
                          ) : (
                            "  +91 95131 67456"
                          )}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">
                          <MailOutlineIcon className={classes.icon} />{" "}
                          info@shipnext.in
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <Typography variant="body2">Follow us : </Typography>
                      </Grid>
                      {socialMedia.map((item, index) => (
                        <Grid item key={index}>
                          <a
                            href={item.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={classes.socialIcon}
                          >
                            {item.icon}
                          </a>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>{" "}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            lg={11}
            style={{ marginTop: "10px" }}
            className={classes.padding1}
          >
            <Grid container spacing={2} direction="row">
              <Grid item>
                <Typography variant="caption">
                  Copyright &#169; {new Date().getFullYear()} WeTruck - All
                  Rights Reserved.
                </Typography>
              </Grid>

              <Grid item>
                <Link
                  to="/privacy"
                  // replace
                  style={{ textDecoration: "none" }}
                  className={classes.textLink}
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  <Typography variant="caption">Privacy Notice</Typography>
                </Link>{" "}
              </Grid>
              <Grid item>
                <Link
                  to="/termsConditions"
                  // replace
                  style={{ textDecoration: "none" }}
                  className={classes.textLink}
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  <Typography variant="caption">
                    Terms &#38; Conditions{" "}
                  </Typography>
                </Link>{" "}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </footer>
  );
};

export default Footer;

// import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import { List, ListItem, Avatar, Typography } from "@material-ui/core";
// import { Room, Phone, Email } from "@material-ui/icons";

// const useStyles = makeStyles(theme => ({
//   footer: {
//     width: "100%",
//     backgroundColor: "#242424",
//     position: "relative",
//     bottom: "0",
//     padding: "20px",
//     [theme.breakpoints.down("sm")]: {
//       padding: "5px"
//     }
//   },
//   lightWeight: {
//     fontWeight: "550",
//     color: "white"
//   },
//   noUnderline: {
//     textDecoration: "none",
//     color: "white",
//     fontWeight: "bold",
//     "&:hover": {
//       textDecoration: "none",
//       color: "rgb(146, 144, 248)"
//     }
//   },
//   marginLeft15: {
//     marginLeft: "15px"
//   },
//   avatar: {
//     backgroundColor: "#858585"
//   }
// }));
// const Footer = props => {
//   const classes = useStyles();
//   return (
//     <>
//       <div className={classes.footer}>
//         <List className={classes.lightWeight}>
//           <ListItem>
//             <Avatar className={classes.avatar}>
//               <Room style={{ fontSize: "28px" }} />
//             </Avatar>

//             <Typography className={classes.marginLeft15} variant="subtitle1">
//               No. 34/137 A, 2nd Floor,Eastern Corporate office, NH Bypass{" "}
//               <br></br>
//               Edapally P O, Kochi, India, 682024
//             </Typography>
//           </ListItem>
//           <ListItem>
//             <Avatar className={classes.avatar}>
//               <Phone style={{ fontSize: "28px" }} />
//             </Avatar>
//             <Typography className={classes.marginLeft15} variant="subtitle1">
//               +91 9846906596
//             </Typography>
//           </ListItem>

//           <ListItem>
//             <Avatar className={classes.avatar}>
//               <Email style={{ fontSize: "28px" }} />
//             </Avatar>
//             <a href="mailto:info@shipnext.in" className={classes.noUnderline}>
//               <Typography className={classes.marginLeft15} variant="subtitle1">
//                 info@shipnext.in
//               </Typography>
//             </a>
//           </ListItem>
//         </List>
//         <div style={{ borderTop: "1px solid lightgrey", padding: "10px 0px" }}>
//           <Typography
//             align="center"
//             className={classes.lightWeight}
//             variant="subtitle1"
//           >
//             Copyright &copy; 2019 shipnext.in
//           </Typography>
//         </div>
//       </div>
//     </>
//   );
// };

// Footer.propTypes = {};

// export default Footer;
