import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
// import Apple from "../../images/apple.png";
import GPlay from "../../images/gplay.png";

const useStyles = makeStyles((theme) => ({
  logoImage: {
    // width: "80%",
    width: "150px",
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.down("sm")]: {
      width: "125px",
    },
  },
}));
const AppLinks = ({ callingFor }) => {
  const classes = useStyles();
  const fleetOwnerApp = process.env.REACT_APP_MOBILE_APP_PARTNER;
  const customerApp = process.env.REACT_APP_MOBILE_APP_CUSTOMER;

  return (
    <div>
      <Grid
        container
        direction={callingFor === "business" ? "column" : "row"}
        spacing={2}
      >
        <Grid item>
          <a
            href={fleetOwnerApp}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            {" "}
            <img src={GPlay} alt="apple" className={classes.logoImage} />{" "}
          </a>
          <br />
          <Typography variant="caption">
            {callingFor === "business"
              ? "For Fleet Owner (who own trucks)"
              : "For Fleet Owner"}
          </Typography>
        </Grid>
        <Grid item>
          <a
            href={customerApp}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <img src={GPlay} alt="g-play" className={classes.logoImage} />{" "}
          </a>
          <br />
          <Typography variant="caption">
            {callingFor === "business"
              ? "For Customer (who need trucks)"
              : "For Customer"}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default AppLinks;
