import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    // primary: { main: "#28a745" },
    primary: { main: "#01B551", bg: "#66B654", boxShadow: "#0e582f74" },
    secondary: { main: "#ffa000" },
    grey: {
      // main: "#f5f5f5",
      main: "#A7A7A7",
      light: "#F8F9FB",
      dark: "#ECEFF4",
      // border: "#D3D3D3",
      border: "#ccc",
      blackDashed: "#2f2f2f",
      bg: "#f5f5f5",
    },
    white: { main: "#FFFFFF" ,bg: "#fafafa"},

    error: { main: "#d50000" },
    textPrimary: { main: "#44566C" },
    textSecondary: { main: "grey" },
  },
  typography: {
    fontFamily: "Lexend Deca",
    // fontWeightBold: "bold",

    subtitle1: {
      // fontSize: "0.96rem",
      fontWeight: 550,
    },
    h1: {
      fontSize: "62px",
      fontFamily: "Lexend Deca",
      // lineSpacing: "10px",
      // color: "#44566C",
      // color: "black",
      fontWeight: "600",
      lineHeight: "65px",

      "@media (max-width:900px)": {
        fontSize: "30px",
        lineHeight: "50px",
      },
    },
    h2: {
      fontSize: "53px",
      fontFamily: "Lexend Deca",
      // lineSpacing: "10px",
      // color: "#44566C",
      // color: "black",
      fontWeight: "600",
      // lineHeight: "65px",

      "@media (max-width:900px)": {
        fontSize: "25px",
        // lineHeight: "55px",
      },
    },
    h3: {
      fontSize: "42px",
      fontFamily: "Lexend Deca",
      // lineSpacing: "10px",
      // color: "#44566C",
      // color: "black",
      fontWeight: "550",
      // lineHeight: "65px",

      "@media (max-width:900px)": {
        fontSize: "30px",
        // lineHeight: "55px",
      },
    },
    h4: {
      fontSize: "1.5rem",
      // fontFamily: "roboto",
      // fontWeight: "bold",
      // color: "#44566C",

      "@media (max-width:600px)": {
        // fontSize: "28px",
      },
    },
    h5: {
      fontSize: "30px",
      // fontFamily: "roboto",
      // color: "#44566C",
      "@media (max-width:900px)": {
        fontSize: "22px",
      },
    },
    h6: {
      fontSize: "20px",
      // fontWeight: "bold",
      // color: "#44566C",

      "@media (max-width:600px)": {
        fontSize: "18px",
      },
    },
    body1: {
      fontSize: "16px",
      fontWeight: 550,

      "@media (max-width:900px)": {
        fontSize: "14px",
      },
    },
    body2: {
      fontSize: "15px",
      // fontWeight: 550,
      "@media (max-width:900px)": {
        fontSize: "14px",
      },
    },
    button: {
      textTransform: "capitalize",
    },
  },
  // typography: {
  //   useNextVariants: true,
  //   // Use the system font instead of the default Roboto font.
  //   fontFamily: [
  //     "-apple-system",
  //     "BlinkMacSystemFont",
  //     '"Segoe UI"',
  //     "Roboto",
  //     '"Helvetica Neue"',
  //     "Arial",
  //     "sans-serif",
  //     '"Apple Color Emoji"',
  //     '"Segoe UI Emoji"',
  //     '"Segoe UI Symbol"'
  //   ].join(","),
  //   fontSize: 14,
  //   caption: {
  //     color: "grey"
  //   },
  //   body2: {
  //     fontWeight: 600,
  //     "@media (max-width:600px)": {
  //       fontSize: "0.9rem"
  //     }
  //   },
  //   body1: {
  //     fontWeight: 500
  //   },

  //   h5: {
  //     fontWeight: 600,
  //     "@media (max-width:600px)": {
  //       fontSize: "1.3rem"
  //     }
  //   },
  //   h6: {
  //     fontWeight: "bold",
  //     "@media (max-width:600px)": {
  //       fontSize: "1.1rem"
  //     }
  //   },

  //   button: {
  //     fontStyle: "bold"
  //   }
  // }
});

export default theme;
