import React, { Suspense, lazy } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";

import BgVideo from "../../images/bg_new.mp4";
import InclinedMobileBg from "../../images/inclinedBg.png";

import BannerSection from "./BannerSection";

const ServiceSection = lazy(() => import("./ServiceSection"));
const BenefitsSection = lazy(() => import("./BenefitsSection"));
const OnlineBusinessSection = lazy(() => import("./OnlineBusinessSection"));
const HowItWorksSection = lazy(() => import("./HowItWorksSection"));
const CustomerSaySection = lazy(() => import("./CustomerSaySection"));
const ClientSection = lazy(() => import("./ClientSection"));

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: theme.palette.white.bg,
    // position: "relative",
    // color: theme.palette.white.main,
  },
  downloadApp: {
    marginTop: "50px",
    padding: theme.spacing(3),

    position: "relative",
    backgroundImage: `url(${InclinedMobileBg})`,
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `none`,
      padding: theme.spacing(0),
      marginTop: theme.spacing(2.5),
    },
    // url(`data:image/svg+xml;utf8,${svgTxt}`)
  },
  relativeDiv: {
    [theme.breakpoints.down("sm")]: {
      position: "relative",
    },
  },
  videoBg: {
    position: "absolute",
    right: "0",
    bottom: "0",
    minWidth: "100%",
    minHeight: "100%",
    opacity: "0.1",
    zIndex: 0,
  },
}));
const AllSectionsContainer = (props) => {
  const classes = useStyles();
  const {
    uspRef,
    homeRef,
    benefitsRef,
    downloadAppsRef,
    howItWorksRef,
    customerSayRef,
    ourClientsRef,
    setUserOverlay,
    setRegnClicked,
  } = props;

  return (
    <Grid container justify="center" className={classes.root}>
      <Grid item xs={12} lg={11} className={classes.relativeDiv}>
        <video autoPlay muted loop id="myVideo" className={classes.videoBg}>
          <source src={BgVideo} type="video/mp4" />{" "}
        </video>
        <Grid container>
          <Grid item xs={12} ref={homeRef}>
            <BannerSection
              setUserOverlay={setUserOverlay}
              setRegnClicked={setRegnClicked}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} ref={uspRef}>
        <Suspense
          fallback={<Typography variant="body2">Loading...</Typography>}
        >
          <ServiceSection />
        </Suspense>
      </Grid>
      <Grid item xs={12} ref={benefitsRef}>
        <Suspense
          fallback={<Typography variant="body2">Loading...</Typography>}
        >
          <BenefitsSection />
        </Suspense>
      </Grid>
      <Suspense fallback={<Typography variant="body2">Loading...</Typography>}>
        <Grid item xs={12}>
          <div className={classes.downloadApp}>
            <Grid container justify="center" spacing={2}>
              <Grid item xs={12} ref={downloadAppsRef}>
                <OnlineBusinessSection />
              </Grid>
              <Grid item xs={12} lg={10} ref={howItWorksRef}>
                <HowItWorksSection howItWorksRef={howItWorksRef} />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Suspense>
      <Suspense fallback={<Typography variant="body2">Loading...</Typography>}>
        <Grid item xs={12} ref={customerSayRef}>
          <CustomerSaySection />
        </Grid>
        <Grid item xs={12} ref={ourClientsRef}>
          <ClientSection />
        </Grid>
      </Suspense>
    </Grid>
  );
};

export default AllSectionsContainer;
