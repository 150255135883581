import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "white",
    height: "100vh",
  },
  text404: {
    fontSize: "7rem",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "4rem",
    },
  },
  link: {
    textDecoration: "none",
  },
  linkText: {
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.dark,
    },
  },
}));
const NotFoundPage = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      className={classes.container}
      justify="center"
      alignItems="center"
      direction="column"
    >
      <Grid item style={{ textAlign: "center", alignItems: "center" }}>
        <Typography
          color="primary"
          className={classes.text404}
          display="inline"
        >
          404
        </Typography>
      </Grid>
      <Grid item style={{ textAlign: "center" }}>
        <Typography variant="h5" color="textSecondary" gutterBottom>
          <strong> oops ! This page doesn't exist.</strong>
        </Typography>
        <br />
        <br />

        <Link to="/" className={classes.link}>
          <Typography variant="h4" className={classes.linkText}>
            <strong> Go to Home page </strong>
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );
};

export default NotFoundPage;
