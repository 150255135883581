import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// import logo from "../images/Shipnext_Logo.png";
import logo from "../images/wetruck_logo.svg";
// import WeTruckLogo from "../images/icons/WeTruckLogo";
import {
  Grid,
  makeStyles,
  Button,
  Typography,
  useMediaQuery,
  Popover,
} from "@material-ui/core";
// import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import MenuIcon from "@material-ui/icons/Menu";
import CallUsDialog from "../components/weTruckHome/CallUsDialog";
// import "./Header.css";
const useStyles = makeStyles((theme) => ({
  headerRoot: {
    backgroundColor: theme.palette.white.main,
    // padding: `${theme.spacing(2)}px 0px`,
    zIndex: "4",
    position: "fixed",
    top: "0px",
    width: "91%",
    padding: theme.spacing(2),
    borderRadius: "4px",
    // paddingBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      // padding: theme.spacing(2),
      width: "100vw",
    },
  },

  popOverPaper: {
    width: "200px",
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  // menuItems: {
  //   // textAlign: "right",
  //   paddingRight: "25px",
  // },
  menuIcon: {
    position: "relative",
    top: "4px",
    right: "2px",
  },
  link: {
    padding: "5px",
    cursor: "pointer",
    color: "black",

    // fontWeight: "bold",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  logo: {
    paddingTop: "5px",
    width: "220px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: "180px",
    },
  },
  arrowIcon: {
    position: "absolute",
    top: "3px ",
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const { setUserOverlay, setRegnClicked } = props;

  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showCallusDialog, setShowCallUsDialog] = useState(false);

  const [showCompanyMenu, setShowComapnyMenu] = useState(false);

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  let navigate = useNavigate();

  const getMenuItems = () => {
    return (
      <Grid
        container
        spacing={1}
        alignItems={!isSmallScreen ? "center" : "flex-start"}
        direction={isSmallScreen ? "column" : "row"}
      >
        <Grid item>
          <Typography
            display="inline"
            variant="body2"
            className={classes.link}
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              navigate("/", { replace: true });
            }}
          >
            <strong>Home</strong>
          </Typography>
        </Grid>
        {/* <Grid item style={{ position: "relative" }}>
          <Typography
            display="inline"
            variant="body2"
            className={classes.link}
            onClick={(e) => {
              setShowComapnyMenu(e.currentTarget);
            }}
          >
            <strong>
              Company
              <ArrowDropDownIcon className={classes.arrowIcon} />
              &nbsp;&nbsp;&nbsp;
            </strong>
          </Typography>
        </Grid> */}

        <Grid item>
          <Typography
            display="inline"
            variant="body2"
            className={classes.link}
            onClick={() => {
              setShowCallUsDialog(true);
            }}
          >
            <strong>Contact</strong>
          </Typography>
          {showCallusDialog && (
            <CallUsDialog
              contactNumber={true}
              showCallusDialog={showCallusDialog}
              setShowCallUsDialog={setShowCallUsDialog}
            />
          )}
        </Grid>

        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                style={{ color: "white" }}
                size="small"
                onClick={() => {
                  setUserOverlay(true);
                  setRegnClicked(false);
                }}
              >
                Register
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                style={{ color: "white" }}
                size="small"
                onClick={() => {
                  setUserOverlay(true);
                  setRegnClicked(true);
                }}
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <header>
      {/* <Grid container alignItems="center" style={{ padding: "5px" }}>
        <Grid item xs={12} md={7}>
          <img src={logo} className={classes.logo} alt="logo" />
        </Grid>
        <Grid item xs={12} md={5} className={classes.menuItems}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setUserOverlay(true);
              setRegnClicked(false);
            }}
          >
            <Typography>Register</Typography>
          </Button>
          &nbsp; &nbsp;
          <Typography
            display="inline"
            variant="body1"
            className={classes.link}
            onClick={() => {
              setUserOverlay(true);
              setRegnClicked(true);
            }}
          >
            LOGIN
          </Typography>
        </Grid>
      </Grid> */}

      <Grid
        container
        justify="space-between"
        direction="row"
        className={classes.headerRoot}
        alignItems={!isSmallScreen ? "center" : "flex-start"}
      >
        <Grid item>
          <img
            src={logo}
            className={classes.logo}
            alt="logo"
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              navigate("/", { replace: true });
            }}
          />
          {/* <div>
            <WeTruckLogo />
          </div> */}

          {showMobileMenu && isSmallScreen ? getMenuItems() : ""}
        </Grid>
        <Grid item>
          {!isSmallScreen ? (
            getMenuItems()
          ) : (
            <MenuIcon
              className={classes.menuIcon}
              onClick={() => {
                setShowMobileMenu(!showMobileMenu);
              }}
            />
          )}
        </Grid>
      </Grid>

      {showCompanyMenu && (
        <Popover
          open={Boolean(showCompanyMenu)}
          classes={{ paper: classes.popOverPaper }}
          anchorEl={showCompanyMenu}
          onClose={() => {
            setShowComapnyMenu(false);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2" className={classes.link}>
                About us
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" className={classes.link}>
                Why Choose us
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" className={classes.link}>
                Our Story
              </Typography>
            </Grid>
          </Grid>
        </Popover>
      )}
    </header>
  );
};

export default Header;
