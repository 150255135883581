import React from 'react';
import MaskedInput from 'react-text-mask';

function MobileNumber(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
      ref={ref => {
        inputRef && inputRef(ref ? ref.inputElement : null);
      }}
      mask={['+', 9, 1,  /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/,/\d/,/\d/,/\d/]}
      placeholderChar={'\u2000'}
      
      />
    );
  }
  
  // MobileNumber.propTypes = {
  //   inputRef: PropTypes.func.isRequired,
  // };

  export default MobileNumber;