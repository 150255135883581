import React, { useEffect, useState } from "react";
// import styles from "./UserInteraction.module.css";
import PropTypes from "prop-types";

import { makeStyles, Grid, Typography } from "@material-ui/core";
import { LocalShipping, Close, Group, ArrowForward } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "30px",
    padding: "30px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
      padding: "10px",
    },
  },
  hrStyle: {
    maxWidth: "500px",
    marginLeft: "0px",
  },
  userInteractionContainer: {
    position: "fixed",
    top: "70px",
    width: "100%",
    backgroundColor: "#e9f5d7",
    zIndex: "99",
    animation: "mymove 1s",
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",

      top: "103px",
    },
  },
  userInteractionContainerClose: {
    position: "fixed",
    top: "70px",
    width: "100%",
    backgroundColor: "#e9f5d7",
    zIndex: "99",
    animation: "mymoveClose 1s",
    height: "0vh",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",

      top: "103px",
    },
  },
  closeDiv: {
    marginLeft: "auto",
    width: "35px",
    padding: "8px",
    marginRight: "8px",
  },
  icon: {
    position: "relative",
    top: "10px",
    fontSize: "40px",
  },
  link: {
    color: "black",
    textDecoration: "none",
    "&:hover": {
      color: "green",
    },
  },
}));

const UserInteractionOverLay = (props) => {
  const [isClosed, setClosed] = useState(false);
  const classes = useStyles();

  const handleScroll = () => {
    props.closeOverlay();
  };

  const initiateClose = () => {
    setTimeout(setClosed(true), 800);
    setTimeout(props.closeOverlay, 800);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  const loginSection = [
    {
      link: `${process.env.REACT_APP_PARTNER_REDIRECTURL}/login`,
      faIcon: <LocalShipping style={{ fontSize: 60 }} />,
      title: "Fleet Owner Sign In",
      description: "Grab new orders and manage new trucks",
    },
    {
      link: `${process.env.REACT_APP_CUSTOMER_REDIRECTURL}/login`,
      faIcon: <Group style={{ fontSize: 60 }} />,
      title: "Customer Sign In",
      description: "Post new Requirements and track orders",
    },
  ];

  const regnSection = [
    {
      link: `${process.env.REACT_APP_PARTNER_REDIRECTURL}/Register`,
      faIcon: <LocalShipping style={{ fontSize: 60 }} />,
      title: "Fleet Owner Sign Up",
      description: "The ones who have truck",
    },
    {
      link: `${process.env.REACT_APP_CUSTOMER_REDIRECTURL}/userRegistration`,
      faIcon: <Group style={{ fontSize: 60 }} />,
      title: "Customer Sign Up",
      description: "The ones who need to send loads",
    },
  ];

  const displaySection = props.isUserRgn ? loginSection : regnSection;

  const renderDisplaySection = (item, index) => (
    <React.Fragment key={index}>
      {/* <i
        className={`fas ${item.faIcon}  fa-3x`}
        style={{ paddingBottom: "15px" }}
      /> */}
      <div>{item.faIcon}</div>
      <a
        href={item.link}
        className={classes.link}
        onClick={() => initiateClose()}
      >
        {/* <br></br> */}
        <Typography variant="h4" display="inline">
          {item.title}
          <span style={{ marginLeft: "20px" }}>
            <ArrowForward className={classes.icon}></ArrowForward>
          </span>
        </Typography>
      </a>{" "}
      <hr className={classes.hrStyle} />
      <Typography variant="body2">{item.description}</Typography>
    </React.Fragment>
  );

  return (
    <div
      className={
        isClosed
          ? classes.userInteractionContainerClose
          : classes.userInteractionContainer
      }
    >
      <div className={classes.closeDiv}>
        <Close
          style={{ cursor: "pointer" }}
          onClick={() => initiateClose()}
          fontSize="large"
        />{" "}
      </div>
      <Grid container className={classes.container} justify="center">
        {displaySection.map((item, index) => (
          <Grid item xs={12} md={5} key={index} style={{ marginTop: "50px" }}>
            {!isClosed && renderDisplaySection(item)}
          </Grid>
        ))}
      </Grid>
      {/* <div className="container-fluid" style={{ height: "100%" }}>
        <div className={`row ${classes.rowStyle}`}>
          {displaySection.map((item, index) => (
            <div
              className={`col-md-6 ${index > 0 ? "mt-5 mt-md-0" : ""}`}
              key={index}
            >
             
            </div>
          ))}
        </div>
      </div> */}
    </div>
  );
};

UserInteractionOverLay.propTypes = {
  isUserRgn: PropTypes.bool,
  closeOverlay: PropTypes.func,
};

export default UserInteractionOverLay;
