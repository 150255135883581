import React, { useState, useRef } from "react";
import { Routes, Route } from "react-router-dom";

import { Grid, makeStyles } from "@material-ui/core";
import Header from "../Header";
import UserInteractionOVerlay from "../UserInteractionOverLay";
import Footer from "../Footer";
import AllSectionsContainer from "./AllSectionsContainer";
import NotFoundPage from "../utils/NotFoundPage";
import Privacy from "../Privacy";
import TermsConditions from "../TermsConditions";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: theme.palette.white.bg,
    // position: "relative",
    // color: theme.palette.white.main,
  },
}));

const HomeSection = (props) => {
  const classes = useStyles();
  const [isRegnClicked, setRegnClicked] = useState(false);

  const [showUserOverlay, setUserOverlay] = useState(false);

  const homeRef = useRef(null);
  const uspRef = useRef(null);
  const benefitsRef = useRef(null);
  const downloadAppsRef = useRef(null);
  const howItWorksRef = useRef(null);
  const customerSayRef = useRef(null);
  const ourClientsRef = useRef(null);

  const scrollToRef = (ref, id) => {
    ref.current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
    // window.scrollTo({ top: ref.current.offsetTop - 72, behavior: "smooth" });
    // console.log("how it", id);
    // document.getElementById("howItWorks").scrollIntoView({
    //   behavior: "smooth",
    // });
  };

  return (
    <Grid container justify="center" className={classes.root}>
      <Grid item xs={12} lg={11} style={{ zIndex: "5" }}>
        <Header
          showUserOverlay={showUserOverlay}
          setUserOverlay={setUserOverlay}
          setRegnClicked={setRegnClicked}
        />
      </Grid>

      <Routes>
        <Route
          path="/"
          exact
          element={
            <AllSectionsContainer
              uspRef={uspRef}
              homeRef={homeRef}
              setUserOverlay={setUserOverlay}
              setRegnClicked={setRegnClicked}
              benefitsRef={benefitsRef}
              downloadAppsRef={downloadAppsRef}
              howItWorksRef={howItWorksRef}
              customerSayRef={customerSayRef}
              ourClientsRef={ourClientsRef}
            />
          }
        />
        <Route path="/privacy" exact element={<Privacy />} />
        <Route path="/termsConditions" exact element={<TermsConditions />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>

      <Grid item xs={12}>
        <Footer
          scrollToRef={scrollToRef}
          uspRef={uspRef}
          homeRef={homeRef}
          benefitsRef={benefitsRef}
          downloadAppsRef={downloadAppsRef}
          howItWorksRef={howItWorksRef}
          customerSayRef={customerSayRef}
          ourClientsRef={ourClientsRef}
        />
      </Grid>
      {showUserOverlay ? (
        <UserInteractionOVerlay
          isUserRgn={isRegnClicked}
          closeOverlay={() => setUserOverlay(false)}
        />
      ) : null}
    </Grid>
  );
};

export default HomeSection;
