import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: theme.palette.white.bg,
    marginTop: "80px",
    marginBottom: "20px",
    padding: theme.spacing(2),
    // position: "relative",
    // color: theme.palette.white.main,
  },
//   textCenter: {
//     textAlign: "center",
//   },
}));

const TermsConditions = (props) => {
  const classes = useStyles();

  return (
    <Grid container justify="center" className={classes.root}>
      <Grid item xs={12} md={11}>
        <Grid container spacing={3} direction="column">
          <Grid item xs={12} lg={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.textCenter}>
                <Typography variant="h5"> Terms &#38; Conditions</Typography>
              </Grid>
              {/* <Grid item xs={12}>
                <Typography variant="body2"> Updated April 9, 2022</Typography>
              </Grid> */}

              <Grid item xs={12}>
                <Typography variant="body1">
                  Please read these Terms and Conditions carefully before using
                  the https://www.shipnext.in and https://www.wetruck.in
                  operated by our Company. Your access to and use of the Service
                  is conditioned on your acceptance of and compliance with these
                  Terms. These Terms apply to all visitors, users and others who
                  access or use the Service. By accessing or using the Service
                  you agree to be bound by these Terms. If you disagree with any
                  part of the terms then you may not access the Service.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {termsData1.map((data, index) => (
                    <Grid item xs={12} key={index}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant="body1">
                            <strong>
                              {index + 1} . {data.heading}
                            </strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {data.content}
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}  className={classes.textCenter}>
                <Typography variant="h5"> Refund &#38; Cancellation</Typography>
              </Grid>
              {/* <Grid item xs={12}>
                <Typography variant="body2"> Updated April 9, 2022</Typography>
              </Grid> */}

              <Grid item xs={12}>
                <Typography variant="body1">
                  We greatly appreciate and value your business. If you are not
                  satisfied with our services, we're here to help you.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {refundData.map((data, index) => (
                    <Grid item xs={12} key={index}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant="body1">
                            <strong>
                              {index + 1} . {data.heading}
                            </strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {data.content}
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const termsData1 = [
  {
    heading: <>Services: </>,
    content: (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1">
            If you wish to avail any service made available through the website,
            you may be asked to supply certain information relevant to your
            Purchase including your name, contact details, card details, route
            details, cargo details, vehicle details, driver details or any
            detail we find necessary to serve you better.
          </Typography>
        </Grid>
      </Grid>
    ),
  },
  {
    heading: <>Subscriptions: </>,
    content: (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1">
            Some parts of the service are billed on a subscription basis. You
            may be billed in advance on a recurring installment.
          </Typography>
        </Grid>
      </Grid>
    ),
  },
  {
    heading: <>Content: </>,
    content: (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1">
            Our Service allows you to post, link, store, share and otherwise
            make available certain information, text, graphics, videos, or other
            material. We are not responsible for the contents of the links,
            videos etc.
          </Typography>
        </Grid>
      </Grid>
    ),
  },
  {
    heading: <>Links to Other Web Sites: </>,
    content: (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1">
            Our Service may contain links to third party websites or services
            that are not owned or controlled by our Company. Our Company has no
            control over, and assumes no responsibility for, the content,
            privacy policies, or practices of any third party websites or
            services. You further acknowledge and agree that our Company shall
            not be responsible or liable, directly or indirectly, for any damage
            or loss caused or alleged to be caused by or in connection with use
            of or reliance on any such content, goods or services available on
            or through any such websites or services.
          </Typography>
        </Grid>
      </Grid>
    ),
  },
  {
    heading: <>Changes: </>,
    content: (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1">
            We reserve the right, at our sole discretion, to modify or replace
            these Terms at any time. If a revision is material we will try to
            provide at least 30 days' notice prior to any new terms taking
            effect. What constitutes a material change will be determined at our
            sole discretion.
          </Typography>
        </Grid>
      </Grid>
    ),
  },

  {
    heading: <> Contact us: </>,
    content: (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1">
            If you have any questions you can always contact us at:
            info@shipnext.in
          </Typography>
        </Grid>
      </Grid>
    ),
  },
];

const refundData = [
  {
    heading: <>Refunds:</>,
    content: (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1">
            Refunds will be initiated for the following scenarios:
          </Typography>
          <ul>
            <Typography variant="body1">
              <li>
                Transporter failing to pick up your shipment at the scheduled
                time and you request a refund
              </li>
              <li>
                Transporter failing to deliver your shipment on time. In this
                case, the amount previously agreed as late payment fee will be
                refunded upon request.
              </li>
            </Typography>
          </ul>
        </Grid>
      </Grid>
    ),
  },

  {
    heading: <>Cancellation::</>,
    content: (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1">
            Cancellations with refund are permitted only for the below mentioned
            scenarios:
          </Typography>
          <ul>
            <Typography variant="body1">
              <li>
                Transporter failing to pick up your shipment at the scheduled
                time and you request a refund
              </li>
              <li>
                Transporter failing to deliver your shipment on time. In this
                case, the amount previously agreed as late payment fee will be
                refunded upon request.
              </li>
              <li>
                The cancellation request is made 30 minutes prior to the
                scheduled pickup time.
              </li>
            </Typography>
          </ul>
        </Grid>
      </Grid>
    ),
  },
];

export default TermsConditions;
