import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: theme.palette.white.bg,
    marginTop: "80px",
    marginBottom: "20px",
    padding: theme.spacing(2),
    // position: "relative",
    // color: theme.palette.white.main,
  },
//   textCenter: {
//     textAlign: "center",
//   },
}));

const Privacy = (props) => {
  const classes = useStyles();

  return (
    <Grid container justify="center" className={classes.root}>
      <Grid item xs={12} md={11}>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.textCenter}>
                <Typography variant="h5">wetruck.com Privacy Policy</Typography>
              </Grid>
              {/* <Grid item xs={12}>
                <Typography variant="body2"> Updated April 9, 2022</Typography>
              </Grid> */}

              <Grid item xs={12}>
                <Typography variant="body1">
                  M/s. Shipnext Solutions Private Limited, a company
                  incorporated under the Companies Act, 2013, with its
                  Registered Office at XII/184, PWD Road, Panangad,
                  Ernakulam-682506, Kerala, India with the object to develop or
                  acquire an internet portal to carry on business of online
                  freight broker services, online shipping services, online
                  marketing of all types of goods and services and such other
                  activities in furtherance of the aforementioned object is
                  committed in protecting your privacy and the confidentiality
                  of your personal and other information. This privacy policy
                  sets out how we collect and manage your information, how you
                  can access that information if you wish and how you can
                  complain if you are not satisfied with our policies and
                  processes.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {privacyData.map((data, index) => (
                    <Grid item xs={12} key={index}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant="body1">
                            <strong>
                              {index + 1} . {data.heading}
                            </strong>
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          //  style={{ textIndent: "2em" }}
                        >
                          {data.content}
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const privacyData = [
  {
    heading: <>Collection and storage of personal information: </>,
    content: (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1">
            We collect information from, and about you as appropriate to:
          </Typography>
          <ul>
            <Typography variant="body1">
              <li>
                Respond to your enquiries about the goods or the services we can
                source for you
              </li>
              <li>Provide customer support</li>
              <li>Promote our goods and services</li>
              <li>Manage any complaints</li>
            </Typography>
          </ul>
          <Typography variant="body1">
            {" "}
            We also use the information we collect to:
          </Typography>
          <ul>
            <Typography variant="body1">
              <li>Manage your application </li>
              <li>Develop new goods, services, policies and procedures</li>
              <li>Undertake market research</li>
              <li>Obtain legal and compliance advice about our obligations</li>
              <li> Meet our regulatory and legal obligations</li>
            </Typography>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            The information we collect may include personal identification,
            contact details, financial information, lending and default history,
            banking details, personal and trade references and employment or
            business history, depending on the relationship you wish to have
            with us. If you choose not to provide this information, our ability
            to assist you may be restricted.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            Wherever possible, we will collect the information directly from
            you. We may obtain personal information about you from a third
            party, such as your accountant or bank, where authorized. Wherever
            possible we will tell you who we need to contact and why.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            We may also collect information given by you in our website
            www.shipnext.in to communicate with us. We will collect the
            information you provide through our interactive facilities, such as
            customer enquiry, online application or comment forms.
          </Typography>
        </Grid>{" "}
        <Grid item xs={12}>
          <Typography variant="body1">
            In certain circumstances, your agents and government bodies may
            provide us with your information. The circumstances under which
            third parties provide us with information include purchase or
            service requirements, account management and complaints.
          </Typography>
        </Grid>{" "}
        <Grid item xs={12}>
          <Typography variant="body1">
            We take steps to protect any information we collect so that it will
            be safeguarded from misuse, loss, unauthorized access, use,
            disclosure or modification.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            The law may require us to retain your personal information for a
            period of time after our business dealings have finished. It is
            securely stored and disposed of, or de-identified, when no longer
            required.
          </Typography>
        </Grid>
      </Grid>
    ),
  },
  {
    heading: <>Website information collection: </>,
    content: (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1">
            When you visit our website, our ISP host records a range of
            information, including your server address, domain name, the date
            and time of the visit and the pages viewed. This information may be
            collected by using cookies which data is sent to your web browser.
            This allows our site to interact more efficiently with your
            computer.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            If you disable the use of cookies, your use of our site may be
            affected. Information collected about your visit to our site is
            retained for statistical and website development purposes and is not
            in a form which would enable us to identify you.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            When visiting our site, you will not be required to provide us with
            any personal information unless you request information about our
            goods and services or respond to a promotion. If you do, we will ask
            you to provide contact details along with other information required
            to respond to you.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            We may also retain that information provided for planning purposes,
            which may be used for direct marketing purposes as well, unless you
            tell us you do not wish to receive marketing material.
          </Typography>
        </Grid>{" "}
      </Grid>
    ),
  },
  {
    heading: <>Use and disclosure: </>,
    content: (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1">
            We use your information to source the goods and services you
            require, assess your suitability to get it and perform other
            functions and activities associated with managing our relationship.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            Those functions and activities can include our internal
            administration, account management, product or service development,
            compliance audits/reviews, marketing, complaints management,
            planning and research requirements as well as future offers to you.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            We will use your information to respond to you, regarding the reason
            you contacted us. We will not share your information with any third
            party outside of our organization, other than as necessary to
            fulfill your request, e.g. to ship an order.
          </Typography>
        </Grid>{" "}
        <Grid item xs={12}>
          <Typography variant="body1">
            Unless you ask us not to, we may contact you via email in the future
            to tell you about specials, new products or services, or changes to
            this privacy policy.
          </Typography>
        </Grid>{" "}
        <Grid item xs={12}>
          <Typography variant="body1">
            We will disclose your information to third parties only if the law
            permits and where there is a valid reason to do so. All third
            parties must use your information only for the specific purpose for
            which we supply it.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <strong>Third parties may include :</strong>{" "}
          </Typography>
          <ul>
            <Typography variant="body1">
              <li>
                Service providers (e.g. computer systems consultants, document
                custodians, mailing houses etc.) to enable them to perform those
                services)
              </li>
              <li>
                Your agents, including your accountant or legal or financial
                advisor, where required
              </li>
              <li>Insurers, insurance brokers and insurance assessors</li>
              <li>Debt collection agencies</li>
              <li>Companies to which we are related</li>
              <li>
                Government authorities and law enforcement agencies, as required
                by law only.
              </li>
            </Typography>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            Our operations are strictly within India at the beginning. Where
            information is provided to our service providers outside India, we
            require them to comply with our information management policies and
            procedures so that your information has the same protections as if
            managed in India.
          </Typography>
        </Grid>
      </Grid>
    ),
  },
  {
    heading: <>Your Access to and Control over Information: </>,
    content: (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1">
            You can access the personal and/or other information we hold about
            you by contacting us in the manner provided in Clause No. 14 below.
          </Typography>
          <ul>
            <Typography variant="body1">
              <li>See what data we have about you, if any</li>
              <li>Change/correct any data we have about you.</li>
              <li>Have us delete any data we have about you.</li>
              <li>Express any concern you have about our use of your data.</li>
            </Typography>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            If any information is incorrect, out of date or incomplete, you have
            the right to have that information updated and corrected. If, for
            any valid reason, we refuse to correct your information, we will
            explain why.
          </Typography>
        </Grid>
      </Grid>
    ),
  },
  {
    heading: <>Security: </>,
    content: (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1">
            We take precautions to protect your information. When you submit
            sensitive information via the website, your information is protected
            both online and offline.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            Wherever we collect sensitive information (such as credit card
            data), that is encrypted and transmitted to us in a secure way. You
            can verify this by looking for a closed lock icon at the bottom of
            your web browser, or looking for "https" at the beginning of the
            address of the web page.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            While we use encryption to protect sensitive information transmitted
            online, we also protect your information offline. Only employees who
            need the information to perform a specific job (for example, billing
            or customer service) are granted access to personally identifiable
            information. The computers/servers in which we store personally
            identifiable information are kept in a secure environment.
          </Typography>
        </Grid>
      </Grid>
    ),
  },
  {
    heading: <>Registration: </>,
    content: (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1">
            In order to use the website, a user must first complete the
            registration form. During registration a user is required to give
            certain information (such as name, gender, age, contact details and
            email address). This information is used to contact you about the
            products/services on our site.
          </Typography>
        </Grid>
      </Grid>
    ),
  },
  {
    heading: <>Orders: </>,
    content: (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1">
            We request information from you on our order form. To complete a
            transaction, you must provide contact information (like name and
            address) and financial information (like credit card number,
            expiration date). This information is used for billing purposes and
            to fill your orders. If we have trouble processing an order, we'll
            use this information to contact you.
          </Typography>
        </Grid>
      </Grid>
    ),
  },
  {
    heading: <>Cookies :</>,
    content: (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1">
            We use "cookies" on this site. A cookie is a piece of data stored on
            a site visitor's hard drive to help us improve your access to our
            site and identify repeat visitors to our site. For instance, when we
            use a cookie to identify you, you would not have to log in a
            password more than once, thereby saving time while on our site.
            Cookies can also enable us to track and target the interests of our
            users to enhance the experience on our site. Usage of a cookie is in
            no way linked to any personally identifiable information on our
            site.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            Some of our business partners may use cookies on our site (for
            example, advertisers). However, we have no access to or control over
            these cookies.
          </Typography>
        </Grid>
      </Grid>
    ),
  },
  {
    heading: <>Sharing:</>,
    content: (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1">
            We share aggregated demographic information with our partners and
            advertisers. This is not linked to any personal information that can
            identify any individual person.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">And/or:</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            We use a credit card processing company to bill users for the goods
            and services. Such companies do not retain, share, store or use
            personally identifiable information for any secondary purposes
            beyond filling your order.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">And/or:</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            We partner with another party to provide specific services. When the
            user signs up for these services, we will share names, or other
            contact information that is necessary for the third party to provide
            these services. These parties are not allowed to use personally
            identifiable information except for the purpose of providing these
            services.
          </Typography>
        </Grid>
      </Grid>
    ),
  },
  {
    heading: <> Links :</>,
    content: (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1">
            This web site contains links to other sites. Please be aware that we
            are not responsible for the content or privacy practices of such
            other sites. We encourage our users to be aware when they leave our
            site and to read the privacy statements of any other site that
            collects personally identifiable information.
          </Typography>
        </Grid>
      </Grid>
    ),
  },
  {
    heading: <>Surveys &#38; Contests:</>,
    content: (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1">
            From time-to-time our site requests information via surveys or
            contests. Participation in these surveys or contests is completely
            voluntary and you may choose whether or not to participate and
            therefore disclose this information. Information requested may
            include contact information (such as name and shipping address), and
            demographic information (such as zip code, age level). Contact
            information will be used to notify the winners and award prizes.
            Survey information will be used for purposes of monitoring or
            improving the use and satisfaction of this site.
          </Typography>
        </Grid>
      </Grid>
    ),
  },
  {
    heading: <>Complaints and concerns: </>,
    content: (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1">
            Please contact us if you have any complaints or queries about how we
            manage your information or you no longer wish to receive marketing
            materials. You can contact us by mail, phone or email, as provided
            in Clause No. 14 below.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            If you lodge a complaint, our Internal Dispute Resolution officer
            will investigate your complaint. We will commence the investigation
            within 2 days and provide you with a response within 14 days. We
            will seek your agreement to a longer time if it is needed. We will
            keep you informed of the investigation process by phone or email at
            least once a week and provide you with a written response to your
            complaint within 30 days.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            If you do not accept our response, you may take the compliant to our
            below given External Dispute Resolution Scheme:
          </Typography>
          <ul>
            <Typography variant="body1">
              <li>Escalated Dispute Resolution(EDR) via customer support.</li>
              <li>If EDR is a failure, resort to legal proceedings.</li>
            </Typography>
          </ul>
        </Grid>
      </Grid>
    ),
  },
  {
    heading: <>Obtaining our privacy policy: </>,
    content: (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1">
            This privacy policy may change over time. You can obtain the current
            version of the privacy policy from our website or by contacting us
            by any of the methods below:
          </Typography>
          <ul>
            <Typography variant="body1">
              <li>
                By clicking on Privacy Policy placed at the footer of the
                website.
              </li>
              <li>Raise a request via email.</li>
              <li>Raise a request via phone.</li>
            </Typography>
          </ul>
        </Grid>
      </Grid>
    ),
  },
  {
    heading: <> Contact us: </>,
    content: (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1">
            If you feel that we are not abiding by this privacy policy, you
            should contact us immediately via <br />
            telephone no. +91 98469 06596 or via <br />
            email at complaints@shipnext.in
          </Typography>
        </Grid>
      </Grid>
    ),
  },
];

export default Privacy;
